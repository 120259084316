<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Search Voters</div>
    </div>
    <div class="layout-content-section">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="searchbydetails-tab" data-bs-toggle="tab"
                    data-bs-target="#searchbydetail" type="button" role="tab" aria-controls="search-tab-details"
                    aria-selected="true" @click="detailclickbtn">
                    Search by Details
                </button>
            </li>
            <!-- <li class="nav-item" role="presentation">
                <button class="nav-link" id="searchbyepic-tab" data-bs-toggle="tab" data-bs-target="#searchbyEPIC"
                    type="button" role="tab" aria-controls="epic-tab-details" aria-selected="false" @click="epicclickbtn">
                    Search by EPIC
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="searchbymobile-tab" data-bs-toggle="tab" data-bs-target="#searchbymobile"
                    type="button" role="tab" aria-controls="mobile-tab-details" aria-selected="false"
                    @click="mobvoterclickbtn">
                    Search by Mobile No.
                </button>
            </li> -->
        </ul>
        <div class="voter-detail-box-outer">
            <div class="row">
                <div class="col-12">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="searchbydetail" role="tabpanel"
                            aria-labelledby="searchbydetails-tab">
                            <div class="company-section-outer" style="height: calc(100vh - 196px); padding: 16px 16px 0;">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label for="formVoterNameInput" class="form-label">Voter Name<span
                                                    class="text-danger">*</span></label>
                                            <input type="text" v-model="detail.votername"
                                                class="form-control text-capitalize" id="formVoterNameInput"
                                                @keypress="isLetterWithSpace($event)" placeholder="Enter Name"
                                                autocomplete="off" maxlength="50" required />
                                            <div class="custom-error" v-if="v$.detail.votername.$error">
                                                {{ v$.detail.votername.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label for="formFatherNameInput" class="form-label">Father's/Husband's Name<span
                                                    class="text-danger">*</span></label>
                                            <input type="text" v-model="detail.familyname"
                                                class="form-control text-capitalize" id="formFatherNameInput"
                                                @keypress="isLetterWithSpace($event)" placeholder="Enter Name"
                                                autocomplete="off" maxlength="50" required />
                                            <div class="custom-error" v-if="v$.detail.familyname.$error">
                                                {{ v$.detail.familyname.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select Age Or Date of Birth<span
                                                    class="text-danger">*</span></label>
                                            <div class="custom-group-radio-box-btn">
                                                <input type="radio" class="btn-check" v-model="detail.agedobvalue"
                                                    name="age-dob-option" id="agelabel" autocomplete="off" value="1" />
                                                <label class="btn custom-radio-box-btn text-capitalize"
                                                    for="agelabel">Age</label>
                                                <input type="radio" class="btn-check" v-model="detail.agedobvalue"
                                                    name="age-dob-option" id="dateofbirthlabel" autocomplete="off"
                                                    value="2" />
                                                <label class="btn text-capitalize" for="dateofbirthlabel">Date of
                                                    Birth</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12" v-if="this.detail.agedobvalue == 1">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select Age<span class="text-danger">*</span></label>
                                            <Multiselect v-model="detail.age" :options="ageList" :searchable="true"
                                                label="label" placeholder="Select" class="multiselect-custom"
                                                @keypress="onlyNumber" @paste="onPasteMobile" maxlength="3" :canClear="true" :closeOnSelect="true"
                                                :object="true" noOptionsText="No Result" />
                                            <div class="custom-error" v-if="v$.detail.age.$error">
                                                {{ v$.detail.age.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12" v-if="this.detail.agedobvalue == 2">
                                        <div class="custom-form-group">
                                            <label class="form-label">Date of Birth<span
                                                    class="text-danger">*</span></label>
                                            <Calendar v-model="detail.birthdate" :minDate="minDateBirth" :maxDate="maxDateBirth" :showIcon="true"
                                                class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                                dateFormat="dd/mm/yy" :manualInput="false" :monthNavigator="true"
                                                :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body"
                                                :yearNavigator="true">
                                            </Calendar>
                                            <div class="custom-error" v-if="v$.detail.birthdate.$error">
                                                {{ v$.detail.birthdate.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Gender</label>
                                            <Multiselect v-model="detail.gender" :options="genderList" :searchable="false"
                                                label="label" placeholder="Select" class="multiselect-custom"
                                                :canClear="false" :closeOnSelect="true" :object="true"
                                                noOptionsText="No Result" />
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div class="row mt-4">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select State<span class="text-danger">*</span></label>
                                            <Multiselect v-model="detail.state" :options="stateList" :searchable="true"
                                                label="label" placeholder="Select" class="multiselect-custom"
                                                :canClear="true" :closeOnSelect="true" :object="true"
                                                noOptionsText="No Result" @keypress="isLetterWithSpace($event)"
                                                @select="getDistByStateId(detail.state)" required />
                                            <div class="custom-error" v-if="v$.detail.state.$error">
                                                {{ v$.detail.state.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select District<span
                                                    class="text-danger">*</span></label>
                                            <Multiselect v-model="detail.district" :options="districtList"
                                                :searchable="true" label="label" placeholder="Select"
                                                class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                                                :canClear="true" :closeOnSelect="true" noOptionsText="No Result"
                                                :object="true" required />
                                            <div class="custom-error" v-if="v$.detail.district.$error">
                                                {{ v$.detail.district.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <div class="custom-form-group">
                                            <button type="button" class="btn form-submit-btn me-2"
                                                @click="searchByDetailBtn()" :disabled="btndetailloader">
                                                <span v-if="!btndetailloader"><i class="pi pi-search me-1"
                                                        style="font-size: 0.7rem"></i>Search</span>
                                                <div class="spinner-border text-light custom-spinner-loader-btn"
                                                    role="status" v-if="btndetailloader">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                            <button v-if="cleardetailbtnflag" type="button"
                                                class="btn custom-view-detail-btn" @click="searchByDetailClearBtn()">Clear
                                                All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="detaildatatable">
                                    <div class="col-12">
                                        <div class="custom-ultima-datatable">
                                            <DataTable :value="searchByDetailList" :scrollable="true" columnResizeMode="fit"
                                                :paginator="false" :lazy="true" :rowHover="true"
                                                :totalRecords="totalRecords" :rows="30" dataKey="aj1"
                                                @page="changePage($event)"
                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                responsiveLayout="scroll"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                :loading="loading" style="z-index: 1;">
                                                <template v-if="!loading" #empty>No records found.</template>
                                                <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                                                <Column field="id" header="#" headerStyle="width: 2%" bodyStyle="width: 2%">
                                                    <template #body="slotProps">
                                                        <div>{{ slotProps.index + 1 }}</div>
                                                    </template>
                                                </Column>
                                                <Column field="name-id" header="Name / EPIC No." headerStyle="width: 23%"
                                                    bodyStyle="width: 23%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.name }}</div>
                                                            <div>{{ data.name_v1 }}</div>
                                                            <div title="EPIC No.">{{ data.epic_no }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="age" header="Age" headerStyle="width: 5%"
                                                    bodyStyle="width: 5%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.age }}</div>
                                                            <div>{{ data.gender }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="relativename" header="Relative's Name"
                                                    headerStyle="width: 15%" bodyStyle="width: 15%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.relation_name }}</div>
                                                            <div>{{ data.rln_name_v1 }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="state" header="Polling Station" headerStyle="width: 35%"
                                                    bodyStyle="width: 35%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div title="Polling Station Name">{{ data.polling_station_name
                                                            }}</div>
                                                            <div>
                                                                <span title="Assembly Name">{{ data.assembly_name }} ({{
                                                                    data.assembly_number
                                                                }})</span><span class="px-2">|</span>
                                                                <span title="Parliamentary Name">{{ data.parliamentary_name
                                                                }} ({{ data.part_no
}})</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="state" header="Area / District / State"
                                                    headerStyle="width: 20%" bodyStyle="width: 20%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div><span title="Area">{{ data.area }}</span><span
                                                                    class="px-2">|</span><span title="District">{{
                                                                        data.district }}</span></div>
                                                            <div>{{ data.state }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="searchbyEPIC" role="tabpanel" aria-labelledby="searchbyepic-tab">
                            <div class="company-section-outer" style="height: calc(100vh - 196px); padding: 16px 16px 0;">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label for="formEPICNumberInput" class="form-label">EPIC Number
                                                <span class="text-danger">*</span></label>
                                            <input type="text" v-model="epic.epicnumber" class="form-control text-uppercase"
                                                id="formEPICNumberInput" placeholder="Enter EPIC Number" autocomplete="off"
                                                maxlength="10" />
                                            <div class="custom-error" v-if="v$.epic.epicnumber.$error">
                                                {{ v$.epic.epicnumber.$errors[0].$message }}
                                            </div>
                                            <div class="custom-error" v-if="epicerrormsg">
                                                {{ epicerrormsg }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="custom-form-group">
                                            <label class="form-label">Select State</label>
                                            <Multiselect v-model="epic.state" :options="stateList" :searchable="true"
                                                label="label" placeholder="Select" class="multiselect-custom"
                                                :canClear="true" :closeOnSelect="true" :object="true"
                                                noOptionsText="No Result" @keypress="isLetterWithSpace($event)" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <div class="custom-form-group">
                                            <button type="button" class="btn form-submit-btn me-2"
                                                @click="searchByEPICBtn()" :disabled="btnepicloader">
                                                <span v-if="!btnepicloader"><i class="pi pi-search me-1"
                                                        style="font-size: 0.7rem"></i>Search</span>
                                                <div class="spinner-border text-light custom-spinner-loader-btn"
                                                    role="status" v-if="btnepicloader">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                            <button v-if="clearepicbtnflag" type="button" class="btn custom-view-detail-btn"
                                                @click="searchByEPICClearBtn()">Clear All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="epicdatatable">
                                    <div class="col-12">
                                        <div class="custom-ultima-datatable">
                                            <DataTable :value="searchByEPICList" :scrollable="true" columnResizeMode="fit"
                                                :paginator="false" :lazy="true" :rowHover="true"
                                                :totalRecords="totalRecords" :rows="30" dataKey="aj1"
                                                @page="changePage($event)"
                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                responsiveLayout="scroll"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                :loading="loading" style="z-index: 1;">
                                                <template v-if="!loading" #empty>No records found.</template>
                                                <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                                                <Column field="id" header="#" headerStyle="width: 2%" bodyStyle="width: 2%">
                                                    <template #body="slotProps">
                                                        <div>{{ slotProps.index + 1 }}</div>
                                                    </template>
                                                </Column>
                                                <Column field="name-id" header="Name / EPIC No." headerStyle="width: 23%"
                                                    bodyStyle="width: 23%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.name }}</div>
                                                            <div>{{ data.name_v1 }}</div>
                                                            <div title="EPIC No.">{{ data.epic_no }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="age" header="Age" headerStyle="width: 5%"
                                                    bodyStyle="width: 5%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.age }}</div>
                                                            <div>{{ data.gender }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="relativename" header="Relative's Name"
                                                    headerStyle="width: 15%" bodyStyle="width: 15%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.relation_name }}</div>
                                                            <div>{{ data.rln_name_v1 }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="state" header="Polling Station" headerStyle="width: 35%"
                                                    bodyStyle="width: 35%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div title="Polling Station Name">{{ data.polling_station_name
                                                            }}</div>
                                                            <div>
                                                                <span title="Assembly Name">{{ data.assembly_name }} ({{
                                                                    data.assembly_number
                                                                }})</span><span class="px-2">|</span>
                                                                <span title="Parliamentary Name">{{ data.parliamentary_name
                                                                }} ({{ data.part_no
}})</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="state" header="Area / District / State"
                                                    headerStyle="width: 20%" bodyStyle="width: 20%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div><span title="Area">{{ data.area }}</span><span
                                                                    class="px-2">|</span><span title="District">{{
                                                                        data.district }}</span></div>
                                                            <div>{{ data.state }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="searchbymobile" role="tabpanel" aria-labelledby="searchbymobile-tab">
                            <div class="company-section-outer" style="height: calc(100vh - 196px); padding: 16px 16px 0;">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group mb-0">
                                            <label class="form-label">Mobile Number<span
                                                    class="text-danger">*</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-12">
                                        <div class="custom-form-group">
                                            <div class="input-group custom-input-group">
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <Multiselect v-model="mob.countrycode" :options="countryCodeList"
                                                        :searchable="true" label="label" placeholder="Select" disabled
                                                        class="multiselect-custom custom-title-multiselect"
                                                        :canClear="false" :closeOnSelect="true" :object="true"
                                                        noOptionsText="No Result" :canDeselect="false" />
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-12">
                                                    <input type="text" v-model="mob.votermobile" class="form-control"
                                                        id="searchmobInput" disabled placeholder="Enter Mobile No." maxlength="10"
                                                        @keypress="mobileSearchFormat" @paste="onPasteMobile" autocomplete="off" />
                                                </div>
                                            </div>
                                            <div class="custom-error" v-if="v$.mob.votermobile.$error">
                                                {{ v$.mob.votermobile.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="!displayMobilePopUp">
                                        <div class="custom-form-group">
                                            <button type="button" class="header-btn-outer btn btn-primary"
                                                :style="{ width: '100px' }" @click="sendMobileOtp()"
                                                :disabled="mob.votermobile.length != 10">
                                                <span v-if="!showotploader">Send OTP</span>
                                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showotploader">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12" v-if="displayMobilePopUp">
                                        <div class="custom-form-group">
                                            <input type="text" v-model="otp.mobileotp" class="form-control otp-input-space"
                                                id="formaadharcardinput" placeholder="Enter OTP" autocomplete="off"
                                                maxlength="6" minlength="6" @paste="onPasteMobile" @keypress="onlyNumber"
                                                :disabled="mobile_otp_status == 1" />
                                            <span class="custom-error" v-if="mobileotperr">
                                                {{ mobileotperr }}
                                            </span>
                                            <span v-if="timerMobileStatus" class="otp-counter-text position-fixed mt-2">{{
                                                timerMobileInterval
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12" v-if="displayMobilePopUp">
                                        <div class="custom-form-group">
                                            <button type="button" class="header-btn-outer btn btn-success"
                                                @click="verifyMobileOtp()" :style="{ width: '80px' }"
                                                :disabled="mobileotp.length != 6 || showverifyloader">
                                                <span v-if="!showverifyloader">Verify</span>
                                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showverifyloader">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="mobbyvoterdatatable">
                                    <div class="col-12">
                                        <div class="custom-ultima-datatable">
                                            <DataTable :value="searchByMobileList" :scrollable="true" columnResizeMode="fit"
                                                :paginator="false" :lazy="true" :rowHover="true"
                                                :totalRecords="totalRecords" :rows="30" dataKey="aj1"
                                                @page="changePage($event)"
                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                responsiveLayout="scroll"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                                :loading="loading" style="z-index: 1;">
                                                <template v-if="!loading" #empty>No records found.</template>
                                                <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                                                <Column field="id" header="#" headerStyle="width: 2%" bodyStyle="width: 2%">
                                                    <template #body="slotProps">
                                                        <div>{{ slotProps.index + 1 }}</div>
                                                    </template>
                                                </Column>
                                                <Column field="name-id" header="Name / EPIC No." headerStyle="width: 23%"
                                                    bodyStyle="width: 23%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.name }}</div>
                                                            <div>{{ data.name_v1 }}</div>
                                                            <div title="EPIC No.">{{ data.epic_no }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="age" header="Age" headerStyle="width: 5%"
                                                    bodyStyle="width: 5%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.age }}</div>
                                                            <div>{{ data.gender }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="relativename" header="Relative's Name"
                                                    headerStyle="width: 15%" bodyStyle="width: 15%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div>{{ data.relation_name }}</div>
                                                            <div>{{ data.rln_name_v1 }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="state" header="Polling Station" headerStyle="width: 35%"
                                                    bodyStyle="width: 35%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div title="Polling Station Name">{{ data.polling_station_name
                                                            }}</div>
                                                            <div>
                                                                <span title="Assembly Name">{{ data.assembly_name }} ({{
                                                                    data.assembly_number
                                                                }})</span><span class="px-2">|</span>
                                                                <span title="Parliamentary Name">{{ data.parliamentary_name
                                                                }} ({{ data.part_no
}})</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="state" header="Area / District / State"
                                                    headerStyle="width: 20%" bodyStyle="width: 20%">
                                                    <template #body="{ data }">
                                                        <div>
                                                            <div><span title="Area">{{ data.area }}</span><span
                                                                    class="px-2">|</span><span title="District">{{
                                                                        data.district }}</span></div>
                                                            <div>{{ data.state }}</div>
                                                        </div>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventService from '../../service/EventService';
import ApiService from "../../service/ApiService";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            detail: {
                votername: '',
                familyname: '',
                agedobvalue: 1,
                age: "",
                gender: "",
                birthdate: null,
                state: "",
                district: "",
            },
            epic: {
                epicnumber: '',
                state: "",
            },
            ageList: [],
            genderList: [
                { value: "1", label: "Male" },
                { value: "2", label: "Female" },
                { value: "3", label: "Others" },
            ],
            stateList: [],
            districtList: [],
            btndetailloader: false,
            searchByDetailList: [],
            searchByEPICList: [],
            searchByMobileList: [],
            totalRecords: 0,
            loading: false,
            page_no: 0,
            detaildatatable: false,
            epicdatatable: false,
            mobbyvoterdatatable: false,
            maxDateBirth: moment().subtract(1, "years")._d,
            minDateBirth:  moment().subtract(120, "years")._d,
            clearbtnflag: false,
            errStatus: false,
            epicerrormsg: "",
            countryCodeList: [],
            displayMobilePopUp: false,
            mobileotp: '',
            timerMobileStatus: false,
            timerMobileInterval: null,
            mobileotperr: false,
            mob: {
                countrycode: { value: 101, label: "+91" },
                votermobile: '',
            },
            btnepicloader: false,
            cleardetailbtnflag: false,
            clearepicbtnflag: false,
            showotploader: false,
            showverifyloader: false,
            mobile_otp_status: false,
        };
    },
    watch: {
        votercardnumber(epicnumber) {
            this.validateVoterIdNumber(epicnumber);
            if (epicnumber.length == 0) {
                this.epicerrormsg = "";
                this.errStatus = false;
            }
        },
    },
    validations() {
        return {
            detail: {
                votername: {
                    required: helpers.withMessage("Please enter voter name", required),
                },
                familyname: {
                    required: helpers.withMessage("Please enter father's/husband's name", required),
                },
                state: {
                    required: helpers.withMessage("Please select state", required),
                },
                district: {
                    required: helpers.withMessage("Please select district", required),
                },
                age: {
                    requiredIf: helpers.withMessage(
                        "Please select age",
                        requiredIf(
                            this.detail.agedobvalue == 1
                        )
                    ),
                },
                birthdate: {
                    requiredIf: helpers.withMessage(
                        "Please select date of birth",
                        requiredIf(
                            this.detail.agedobvalue == 2
                        )
                    ),
                },
            },
            mob: {
                votermobile: {
                    required: helpers.withMessage("Please enter Mobile No.", required),
                },
            },
            epic: {
                epicnumber: {
                    required: helpers.withMessage("Please enter EPIC number", required),
                },
            },

        };
    },
    ApiService: null,
    eventService: null,
    created() {
        this.ApiService = new ApiService();
        this.eventService = new EventService();
    },
    mounted() {
        this.getEvents();
        this.getstates();
    },
    methods: {
        detailclickbtn() {
            this.epic.epicnumber  = '';
            this.epic.state = '';
            this.mob.votermobile = '';
            this.epicdatatable = false;
            this.mobbyvoterdatatable = false;
        },
        epicclickbtn() {
            this.detail.votername = '';
            this.detail.familyname = '';
            this.detail.agedobvalue = 1;
            this.detail.age = '';
            this.detail.gender = '';
            this.detail.birthdate = null;
            this.detail.state = '';
            this.detail.district = '';
            this.mob.votermobile = '';
            this.detaildatatable = false;
            this.mobbyvoterdatatable = false;
        },
        mobvoterclickbtn() {
            this.detail.votername = '';
            this.detail.familyname = '';
            this.detail.agedobvalue = 1;
            this.detail.age = '';
            this.detail.gender = '';
            this.detail.birthdate = null;
            this.detail.state = '';
            this.detail.district = '';
            this.epic.epicnumber  = '';
            this.epic.state = '';
            this.detaildatatable = false;
            this.epicdatatable = false;
        },
        getcountrycode(e) {
            this.ApiService.getcountrycode(e).then((data) => {
                if (data.status == 200) {
                    this.countryCodeList = data.data;
                }
            });
        },
        getstates() {
            this.ApiService.getstates().then((data) => {
                if (data.success == true) {
                    this.stateList = data.data;
                }
            });
        },
        getDistByStateId(e) {
            this.detail.district = '';
            this.ApiService.getdistricts({ state: e.value }).then((data) => {
                if (data.success == true) {
                    this.districtList = data.data;
                }
            });
        },
        getEvents(e) {
            this.eventService.getEvents(e).then((data) => {
                this.ageList = data;
            });
        },
        searchByDetailBtn() {
            this.v$.detail.$validate();
            let fields = {};
            fields["voter_name"] = this.detail.votername;
            fields["parent_name"] = this.detail.familyname;
            if (this.detail.agedobvalue == 1) {
                fields["age"] = this.detail.age.value;
            }
            if (this.detail.agedobvalue == 2) {
                fields['dob'] = moment(this.detail.birthdate).format('YYYY-MM-DD');
            }
            fields["gender"] = this.detail.gender.label;
            fields["state"] = this.detail.state.label;
            fields["dist"] = this.detail.district.label;
            if (!this.v$.detail.$error) {
                this.btndetailloader = true;
                this.ApiService.submitvoterdata(fields).then((data) => {
                    if (data.success == true) {
                        this.btndetailloader = false;
                        this.detaildatatable = true;
                        this.searchByDetailList = data.records;
                        this.loading = false;
                        this.cleardetailbtnflag = true;
                    }
                    else {
                        this.searchByDetailList = null;
                        this.totalRecords = 0;
                        this.btndetailloader = false;
                        this.loading = false;
                        this.detaildatatable = true;
                        this.cleardetailbtnflag = true;
                    }
                });
            } else {
                this.$error;
            }
        },
        searchByDetailClearBtn() {
            this.detail.votername = '';
            this.detail.familyname = '';
            this.detail.age = '';
            this.detail.gender = '';
            this.detail.birthdate = null;
            this.detail.state = '';
            this.detail.district = '';
            this.searchByDetailList = null;
            this.detaildatatable = false;
            this.cleardetailbtnflag = false;
        },
        searchByEPICBtn() {
            this.v$.epic.$validate();
            let fields = {};
            fields["epic_number"] = this.epic.epicnumber;
            fields["state"] = this.epic.state.label;
            if (!this.v$.epic.$error) {
                this.btnepicloader = true;
                this.ApiService.submitvoterdata(fields).then((data) => {
                    if (data.success == true) {
                        this.btnepicloader = false;
                        this.epicdatatable = true;
                        this.searchByEPICList = data.records;
                        this.loading = false;
                        this.clearepicbtnflag = true;
                    }
                    else {
                        this.searchByEPICList = null;
                        this.totalRecords = 0;
                        this.btnepicloader = false;
                        this.loading = false;
                        this.epicdatatable = true;
                        this.clearepicbtnflag = true;
                    }
                });
            } else {
                this.$error;
            }
        },
        searchByEPICClearBtn() {
            this.epic.epicnumber = '';
            this.epic.state = '';
            this.searchByEPICList = null;
            this.epicdatatable = false;
            this.clearepicbtnflag = false;
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        validateVoterIdNumber(e) {
            this.errStatus = false;
            if (!this.validVoterId(e)) {
                this.epicerrormsg = "Please enter valid voter id";
                this.errStatus = true;
            }
            if (this.errStatus == false) {
                this.epicerrormsg = "";
                return true;
            }
        },

        validVoterId: function (e) {
            var re = /([A-Za-z]){3}([0-9]){7}$/;
            return re.test(e);
        },
        sendMobileOtp() {
            this.v$.mob.$validate();
            let fields = {};
            fields["mobile_number"] = this.mob.votermobile;
            this.showotploader = true;
            if (!this.v$.mob.$error) {
                this.ApiService.generateaadharotp(fields).then((items) => {
                    if (items.success === true) {
                        this.startMobileTimer();
                        this.timerMobileStatus = true;
                        this.mobileotp = "";
                        this.showotploader = false;
                    } else {
                        var errorMsg = items.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.showotploader = false;
                    }
                });
            } else {
                this.$toast.open({
                    message: "Please enter valid Aadhar No.",
                    type: "warning",
                    duration: 3000,
                    position: "top-right",
                });
            }
        },
        verifyMobileOtp() {
            this.showverifyloader = true;
            this.mobile_otp_status = 0;
            let fields = {};
            fields["otp"] = this.mobileotp;
            this.ApiService.getaadharcarddetails(fields).then((items) => {
                if (items.success === true) {
                    this.showverifyloader = false;
                    this.mobile_otp_status = 1;
                    var successMsg = items.message;
                    this.mobbyvoterdatatable = true;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.searchbymobiledata();
                } else {
                    this.sendOTPMessage = '';
                    this.showverifyloader = false;
                    this.mobbyvoterdatatable = true;
                    this.mobileotperr = items.message;
                }
            });
        },
        searchbymobiledata() {
            // this.ApiService.searchListdata().then((data) => {
            //     if (data.success === true) {
            //         this.loading = false;
            //         this.searchByMobileList = data.records;
            //     } else {
            //         this.loading = false;
            //         var errorMsg = items.message;
            //         this.$toast.open({
            //             message: errorMsg,
            //             type: "error",
            //             duration: 3000,
            //             position: "top-right",
            //         });
            //         this.searchByMobileList = null;
            //     }
            // });
        },
        startMobileTimer() {
            this.timerMobileStatus = true;
            if (this.timerMobileCount > 0) {
                setTimeout(() => {
                    this.timerMobileCount--;
                    this.startMobileTimer();
                }, 1000);
                const minutes = Math.floor(this.timerMobileCount / 60);
                let seconds = this.timerMobileCount % 60;
                if (seconds < 10) {
                    this.timerMobileInterval = `0${minutes}:0${seconds}`;
                } else {
                    this.timerMobileInterval = `0${minutes}:${seconds}`;
                }
            } else {
                this.timerMobileCount = 120;
                this.timerMobileStatus = false;
                this.resentotpbtnstatus = true;
            }
        },
        onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
        mobileSearchFormat($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }

        var mobileInput = document.getElementById("searchmobInput");
        if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
            $event.preventDefault();
        }
    },
    },
};
</script>
<style scoped>
.form-submit-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 25px;
    font-family: "AcuminPro-Regular";
    min-width: 110px;
}

.form-submit-btn:focus {
    box-shadow: none;
}

.custom-ultima-datatable {
    height: 100% !important;
}

.company-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 522px;
}

.company-section-outer::-webkit-scrollbar {
    display: block;
}

.company-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-detail-box-outer {
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
    border-top: 0;
}
</style>